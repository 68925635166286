// Here you can add other styles
#mainHeader{
    background-color: #3BB471 !important;
}
#logoHeader{
    background-color:#12964d !important;
}
.c-header .c-header-nav .c-header-nav-link, .c-header .c-header-nav .c-header-nav-btn {
    color: white;
}
#editorFull{
    border-radius: 20px;
}
#logoutIcon{
        width: 2rem;
    height: 1.5rem;
    font-size: 3rem;
    color: white;
}
#htmlEditor{
    color: white;
}


#logoHeader{
    text-decoration: none;
}
.LogoClass a:hover{
    text-decoration: none;
}
.LogoClass{
text-decoration: none;
font-size: 22px;
font-family: system-ui;
text-align: center;
}
// .LogoClass1{
//     text-decoration: none;
//     font-size: 20px;
//     font-family: system-ui;
//     margin-top: 50px;
//     text-align: center;
//     margin-left: -100px;
//     }
#submit-button{
    text-align: center;
}
.icon_color{
    color: #367fa9;
}
.icon_color_del{
    color: #ee4545;
}
.popup_clr{
     background-color:#12964d;
     color: white;
     text-align: center !important;
     font-size: 20px;
}
.invalid-feedback{
    color: red;
}
    #register, #login {
        width: 300px;
        border: 1px solid #d6d7da;
        padding: 0px 15px 15px 15px;
        border-radius: 5px;
        font-family: arial;
        line-height: 16px;
        color: #333333;
        font-size: 14px;
        background: #ffffff;
        margin: 100px auto;
      }
      
      form label, form input {
        display: block;
        //margin-bottom: 10px;
        width: 90%
      }
      
      form input {
        padding: 10px;
        border: solid 1px #BDC7D8;
      
      }
      
      .button {
        background-color: #00BFFF;
        border-color: #3ac162;
        font-weight: bold;
        padding: 12px 15px;
        color: #ffffff;
      }
      
      .errorMsg {
        color: #cc0000;
        margin-bottom: 12px;
      }
      
      .sucessMsg {
        color: #6B8E23;
        margin-bottom: 10px;
      }

      span.logout_span{
          color: #ffffff;
          text-decoration: none;
          list-style: none;
      }

      span.logout_span:hover{
        color: #ffffff;
        text-decoration: none;
        list-style: none;
    }

    .bg-primary{
        background-color: #3BB371 !important;
    }

    .btn-primary{
        color: #fff;
        background-color: #128f4a;
        border-color: #0e8343;
    }

    .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{
        color: #fff;
        background-color: #128f4a;
        border-color: #0e8343;
    }


    .c-sidebar{
        background: #0a3318;
    }

    .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover{
        background: #0d411e;
    }

    .table{
        background-color: #ffffff;
    }


.editor{
    background: #fff;
padding: 15px;
border: 1px solid rgba(0,0,0,0.2);
}